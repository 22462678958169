
import {
  computed,
  defineComponent,
  inject,
  nextTick,
  onMounted,
  PropType,
  provide,
  ref,
  toRefs
} from "vue";
import { getVatRateTypes } from "@/api/infoteka";
import { ListItemInterface } from "@/models/global/ListItem.interface";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import InputMask from "@/components/UI/InputMask.vue";
import OrderOptions from "@/components/order/OrderOptions.vue";
import PersonItem from "@/components/personItem/index.vue";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import Dimensions from "@/components/order/places/Dimensions.vue";
import PlaceItemFtl from "@/components/order/places/PlaceItemFtl.vue";
import useVuelidate from "@vuelidate/core";
import OrderService from "@/models/order/Order.service";
import { useOrderFieldsInput } from "@/components/order/useOrderFieldsInput";
import ExecutorOrganization from "@/components/order/ExecutorOrganization.vue";
import ForwardingOptions from "@/components/order/ForwardingOptions.vue";
import DataEdo from "@/components/order/DataEdo.vue";
import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";
import { LoadingAddressObjectInterface } from "@/models/order/AddressObject.interface";
import { parseDateString } from "@/utils/dateUtils";

import { useAutocompleteStore } from "@/store/useAutocompleteStore";
import { useUserStore } from "@/store/useUserStore";
import { LoaderItemModel } from "@/models/person/LoaderItem.model";
import { LoadingOwnerItemModel } from "@/models/person/LoadingOwnerItem.model";
import { config } from "@/config";
import Route from "@/components/order/Route/Route.vue";

export default defineComponent({
  name: "OrderItemFtl",
  components: {
    DataEdo,
    Route,
    ForwardingOptions,
    ExecutorOrganization,
    PlaceItemFtl,
    Dimensions,
    PersonItem,
    OrderOptions,
    InputMask
  },
  props: {
    order: {
      type: Object as PropType<OrderItemInterface>,
      required: true
    },
    validateAfterMount: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  setup(props) {
    const { order } = toRefs(props);
    const orderService = new OrderService();
    const autocompleteStore = useAutocompleteStore();
    const userStore = useUserStore();

    const hasEdm = ref<boolean>(false);
    const edmAvailableOrg = config.edmConfig.availableOrganizations;
    const edmAvailable = computed(() => {
      return edmAvailableOrg.some(
        org =>
          org.inn === order.value.executorOrganization?.inn &&
          org.kpp === order.value.executorOrganization?.kpp
      );
    });
    provide("hasEdm", hasEdm);
    provide("edmAvailable", edmAvailable);

    const loadingDates = computed(() => {
      return order.value.places.flatMap((x: PlaceItemInterface) =>
        x.loadingAddresses
          .map((y: LoadingAddressObjectInterface) => {
            if (!y.datePeriod.dateFrom || !y.datePeriod.hourFrom) return null;
            return parseDateString(
              y.datePeriod.dateFrom + " " + y.datePeriod.hourFrom,
              "yyyy-MM-dd HH:mm"
            );
          })
          .filter(x => x !== null)
          .sort()
      );
    });
    provide("loadingDates", loadingDates);

    const ownerCountry = computed<string>(() => userStore.ownerCountry);

    const {
      onAssessedValueInput,
      onPlaceAssessedValueInput,
      onPlaceDimensionsInput,
      onDimensionsInput,
      onPriceInput
    } = useOrderFieldsInput(order);

    const taxesTypes = ref<ListItemInterface[]>([]);
    const loadTaxesTypes = async () => {
      taxesTypes.value = await getVatRateTypes();
    };

    const v$ = useVuelidate({}, order);

    const fetchOrganization = (data: {
      type: OrganizationTypeEnum;
      parentId?: string;
    }) => {
      autocompleteStore.fetchOrganization(data);
    };

    onMounted(() => {
      loadTaxesTypes();
      fetchOrganization({ type: OrganizationTypeEnum.Customer });

      if (!props.validateAfterMount) return;

      v$.value.$touch();
    });

    const addPlace = async () => {
      orderService.addPlaceToOrder(order.value);

      await nextTick();
      v$.value.$touch();
    };
    const onHasEdmChange = async (value: boolean) => {
      hasEdm.value = value;
      orderService.setHasEdmValue(order.value, value);

      if (!value) return;

      setTimeout(() => {
        v$.value.$touch();
      }, 0);
    };
    const resetEdmData = () => {
      order.value.options.cargoCondition = "";
      order.value.options.packingMethod = "";
      order.value.options.containerType = "";

      order.value.places.forEach((place: PlaceItemInterface) => {
        place.loadingAddresses.forEach(
          (addressObj: LoadingAddressObjectInterface) => {
            addressObj.loader = new LoaderItemModel();
            addressObj.loadingOwner = new LoadingOwnerItemModel();
          }
        );
      });
    };
    const setOwnerCountryCode = (countryCode: string) => {
      userStore.SET_OWNER_COUNTRY(countryCode);
      if (countryCode === "RUS") return;

      onHasEdmChange(false);
      resetEdmData();
    };
    const onPlaceAddLoadingAddress = () => {
      nextTick(() => {
        v$.value.$touch();
      });
    };

    return {
      taxesTypes,
      v$,
      hasEdm,
      ownerCountry,
      edmAvailable,
      onDimensionsInput,
      onPlaceDimensionsInput,
      onAssessedValueInput,
      onPlaceAssessedValueInput,
      onPriceInput,
      addPlace,
      loadTaxesTypes,
      setOwnerCountryCode,
      onHasEdmChange,
      onPlaceAddLoadingAddress
    };
  }
});
