import { ContactItemInterface } from "@/models/order/ContactItem.interface";
import { PeriodItemInterface } from "@/models/order/PeriodItem.interface";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import { AddressObjectInterface } from "@/models/order/AddressObject.interface";
import ContactItemModel from "@/models/order/ContactItem.model";
import PeriodItemModel from "@/models/order/PeriodItem.model";
import PersonItemModel from "@/models/person/PersonItem.model";
import { YandexMapAddressItemInterface } from "@/models/yandex-map/YandexMapAddressItem.interface";
import { YandexMapAddressItemModel } from "@/models/yandex-map/YandexMapAddressItem.model";
import { YandexMapAddressItemDataModel } from "@/models/yandex-map/YandexMapAddressItemData.model";
import { generateId } from "@/utils/generateId";

export default class AddressObjectModel implements AddressObjectInterface {
  uid: string;
  address: YandexMapAddressItemInterface;
  contacts: ContactItemInterface[];
  count: string;
  datePeriod: PeriodItemInterface;
  oldAddress?: YandexMapAddressItemInterface;
  person: PersonItemInterface;

  constructor(
    datePeriod?: PeriodItemInterface,
    address?: YandexMapAddressItemInterface
  ) {
    this.uid = generateId();
    this.address = new YandexMapAddressItemModel(address);
    this.contacts = [new ContactItemModel()];
    this.count = "1";
    this.datePeriod = new PeriodItemModel(datePeriod);
    this.oldAddress = new YandexMapAddressItemModel();
    this.person = new PersonItemModel();
  }

  addContact() {
    this.contacts.push(new ContactItemModel());
  }

  setAddress(address: YandexMapAddressItemInterface | null) {
    if (address) {
      this.address.value = address.value;
      this.address.data = address.data;
    } else {
      this.address.value = "";
      this.address.data = new YandexMapAddressItemDataModel();
    }
  }

  removeContact(contactIdx: number) {
    if (this.contacts.length <= 1) return;

    this.contacts.splice(contactIdx, 1);
  }
}
