import { YandexMapAddressItemDataInterface } from "@/models/yandex-map/YandexMapAddressItemData.interface";

export class YandexMapAddressItemDataModel
  implements YandexMapAddressItemDataInterface {
  area: string;
  country: string;
  countryCode: string;
  district: string;
  fullAddress: string;
  house: string;
  latitude: string;
  locality: string;
  longitude: string;
  province: string;
  street: string;
  addressIndex: number;

  constructor() {
    this.area = "";
    this.country = "";
    this.countryCode = "";
    this.district = "";
    this.fullAddress = "";
    this.house = "";
    this.latitude = "";
    this.longitude = "";
    this.locality = "";
    this.province = "";
    this.street = "";
    this.addressIndex = 0;
  }
}
