import {
  getExecutorOrganizations,
  getOrganizationsToForwardOrder,
  importFile
} from "@/api/order";
import {
  PersonCompanyInterface,
  PersonEntrepreneurInterface,
  PersonIndividualInterface,
  PersonType
} from "@/models/person/Person.interface";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import PlaceItemModel from "@/models/order/PlaceItem.model";
import { AddressObjectInterface } from "@/models/order/AddressObject.interface";
import ContactItemModel from "@/models/order/ContactItem.model";
import { ImportOrderListItemInterface } from "@/models/import/ImportOrderListItem.interface";
import ImportOrderListItemModel from "@/models/import/ImportOrderListItem.model";
import { NumberHelper } from "@/utils/Number.helper";
import { OrderServiceInterface } from "@/models/order/Order.service.interface";
import OrderService from "@/models/order/Order.service";
import convertStringToNumber from "@/utils/convertStringToNumber";
import { YandexMapService } from "@/models/yandex-map/YandexMap.service";
import { OrderTypeEnum } from "@/models/order/OrderType.enum";
import {
  ExecutorRoleTypeEnum,
  OrganizationViewInterface
} from "@/models/global/OrganizationView.interface";
import { DimensionsFormInterface } from "@/models/order/DimensionsForm.interface";
import { useUserStore } from "@/store/useUserStore";
import {
  UserActionsInterface,
  UserGettersInterface,
  UserStateInterface
} from "@/models/store/UserState.interface";
import { Store } from "pinia";

export default class ImportService {
  private _orderService: OrderServiceInterface;
  private _userStore: Store<
    "user",
    UserStateInterface,
    UserGettersInterface,
    UserActionsInterface
  >;

  constructor() {
    this._orderService = new OrderService();
    this._userStore = useUserStore();
  }

  private static async fillPerson(
    person: PersonItemInterface,
    data:
      | PersonCompanyInterface
      | PersonEntrepreneurInterface
      | PersonIndividualInterface
  ) {
    person.type = data.type;
    person.countryCode = data.countryCode ?? "RUS";

    if (data.type === PersonType.COMPANY) {
      person.inn = data.inn;
      person.name = data.name;
      return;
    } else {
      person.lastName = data.lastName;
      person.firstName = data.firstName;
      person.middleName = data.middleName;
      person.fullName = person.getFullName();
    }

    if (data.type !== PersonType.ENTREPRENEUR) return;

    person.inn = data.inn;
  }

  private static async fillAddress(
    addressObj: AddressObjectInterface,
    fullAddress: string
  ) {
    const searchedAddress = await YandexMapService.geocode(fullAddress);
    if (searchedAddress.length !== 0) {
      const firstSearchedAddress = searchedAddress[0];
      addressObj.address.value = firstSearchedAddress.value;
      addressObj.address.data = firstSearchedAddress.data;
      return;
    }
    addressObj.address.value = fullAddress;
  }

  //TODO: Разбить метод, чтобы он не был таким длинным
  async importFile(
    file: FormData
  ): Promise<ImportOrderListItemInterface[] | null> {
    const res = await importFile(file);

    if (!res.isSuccess || res.entity.length === 0) {
      return null;
    }

    const executorOrganizations = (await getExecutorOrganizations()).entity;
    const forwardToOrganizationsByExecutorLineClient: Record<
      string,
      OrganizationViewInterface[]
    > = {};

    return await Promise.all(
      res.entity.map(async order => {
        const orderItem: ImportOrderListItemInterface = new ImportOrderListItemModel();
        orderItem.externalId = order.externalId;
        orderItem.type = order.type || "FTL";
        orderItem.price = NumberHelper.numberToString(order.price);
        orderItem.comment = order.comment;
        orderItem.tax = order.tax;
        orderItem.assessedValue = NumberHelper.numberToString(
          order.cost?.assessedValue ?? 0
        );
        if (order.options !== null) {
          orderItem.options.fillFullAssessedValue =
            order.options.fillFullAssessedValue;
          orderItem.options.loadingTypes = order.options.loadingTypes;
          orderItem.options.bodyTypes = order.options.bodyTypes;
          orderItem.options.bodyTypeComment = order.options.bodyTypeComment;
          orderItem.options.cargoCategories = order.options.cargoCategories;
          orderItem.options.cargoSubCategory = order.options.cargoSubCategory;
          orderItem.options.temperatureRegimes =
            order.options.temperatureRegimes;
          orderItem.options.fillFullVolume = order.options.fillFullVolume;
          orderItem.options.wouldBeLoaded =
            order.options.wouldBeLoaded || false;
          orderItem.options.allInSingleTransport =
            order.options.allInSingleTransport || false;
        }
        await ImportService.fillPerson(orderItem.customer, order.customer);
        if (!order.executorLineClientId) {
          orderItem.executorOrganization =
            executorOrganizations && executorOrganizations.length > 0
              ? executorOrganizations[0]
              : null;
        } else {
          orderItem.executorOrganization =
            (executorOrganizations &&
              executorOrganizations.find(
                o => o.id === order.executorLineClientId
              )) ||
            null;
        }
        this._userStore.SET_OWNER_COUNTRY(
          orderItem.executorOrganization?.countryCode ?? ""
        );

        const addressIndexList: number[] = [];
        orderItem.places = await Promise.all(
          order.places.map(async place => {
            const placeItem = new PlaceItemModel();

            placeItem.barcode = place.barcode;
            placeItem.name = place.name;
            placeItem.dimensions = {
              weight: NumberHelper.numberToString(place.dimensions.weight),
              volume: NumberHelper.numberToString(place.dimensions.volume),
              height: NumberHelper.numberToString(place.dimensions.height),
              length: NumberHelper.numberToString(place.dimensions.length),
              width: NumberHelper.numberToString(place.dimensions.width)
            };
            placeItem.assessedValue = NumberHelper.numberToString(
              place.assessedValue
            );

            const loadingAddress = placeItem.loadingAddresses[0];
            const unloadingAddress = loadingAddress.unloadingAddresses[0];

            if (place.loadingAddress.fullAddress.length > 3) {
              await ImportService.fillAddress(
                loadingAddress,
                place.loadingAddress.fullAddress
              );
            }
            if (place.unloadingAddress.fullAddress.length > 3) {
              await ImportService.fillAddress(
                unloadingAddress,
                place.unloadingAddress.fullAddress
              );
            }
            unloadingAddress.count =
              order.type === "LTL" ? place.count.toString() : "1";

            await ImportService.fillPerson(loadingAddress.person, place.sender);
            await ImportService.fillPerson(
              unloadingAddress.person,
              place.recipient
            );

            loadingAddress.address.data.addressIndex =
              place.loadingAddress.addressIndex;
            unloadingAddress.address.data.addressIndex =
              place.unloadingAddress.addressIndex;

            addressIndexList.push(
              place.loadingAddress.addressIndex,
              place.unloadingAddress.addressIndex
            );

            loadingAddress.contacts = place.senderContacts.map(contact => {
              const contactItem = new ContactItemModel();

              contactItem.fullName = `${contact.lastName} ${contact.firstName} ${contact.middleName}`;
              contactItem.phone = contact.phone;
              contactItem.countryCode = contact.countryCode ?? "RUS";

              return contactItem;
            });
            unloadingAddress.contacts = place.recipientContacts.map(contact => {
              const contactItem = new ContactItemModel();

              contactItem.fullName = `${contact.lastName} ${contact.firstName} ${contact.middleName}`;
              contactItem.phone = contact.phone;
              contactItem.countryCode = contact.countryCode ?? "RUS";

              return contactItem;
            });

            loadingAddress.datePeriod.dateFrom = place.shipment.dateFrom;
            loadingAddress.datePeriod.dateTo = place.shipment.dateTo;
            loadingAddress.datePeriod.hourFrom =
              place.shipment.hourFrom?.slice(0, 5) ?? "";
            loadingAddress.datePeriod.hourTo =
              place.shipment.hourTo?.slice(0, 5) ?? "";
            loadingAddress.datePeriod.isInterval =
              !!loadingAddress.datePeriod.dateTo &&
              (loadingAddress.datePeriod.dateFrom !=
                loadingAddress.datePeriod.dateTo ||
                loadingAddress.datePeriod.hourFrom !=
                  loadingAddress.datePeriod.hourTo);

            unloadingAddress.datePeriod.dateFrom = place.delivery.dateFrom;
            unloadingAddress.datePeriod.dateTo = place.delivery.dateTo;
            unloadingAddress.datePeriod.hourFrom =
              place.delivery.hourFrom?.slice(0, 5) ?? "";
            unloadingAddress.datePeriod.hourTo =
              place.delivery.hourTo?.slice(0, 5) ?? "";
            unloadingAddress.datePeriod.isInterval =
              !!unloadingAddress.datePeriod.dateTo &&
              (unloadingAddress.datePeriod.dateFrom !=
                unloadingAddress.datePeriod.dateTo ||
                unloadingAddress.datePeriod.hourFrom !=
                  unloadingAddress.datePeriod.hourTo);

            return placeItem;
          })
        );

        const hasNoValidIndex = addressIndexList.some(a => a === -1);
        const hasEmptyIndex = addressIndexList.some(a => a === 0);
        const hasValidIndex = addressIndexList.some(a => a > 0);

        if (hasNoValidIndex || (hasEmptyIndex && hasValidIndex)) {
          orderItem.isRouteOrderError = true;
        }

        if (orderItem.options.fillFullVolume) {
          Object.keys(orderItem.dimensions).forEach(
            (dimName: keyof DimensionsFormInterface) => {
              if (
                convertStringToNumber(
                  orderItem.places[0].dimensions[
                    dimName as keyof DimensionsFormInterface
                  ]
                ) === 0
              )
                return;

              this._orderService.setDimensionToOrder(
                orderItem,
                dimName,
                orderItem.places[0].dimensions[dimName]
              );
            }
          );
        } else {
          orderItem.places.forEach((place, idx) => {
            Object.keys(place.dimensions).forEach(dimName => {
              if (
                convertStringToNumber(
                  place.dimensions[dimName as keyof DimensionsFormInterface]
                ) === 0
              )
                return;
              this._orderService.setDimensionToOrderPlace(
                orderItem,
                idx,
                dimName as keyof DimensionsFormInterface,
                place.dimensions[dimName as keyof DimensionsFormInterface]
              );
            });
          });
        }

        if (order.options?.fillFullAssessedValue) {
          if (convertStringToNumber(orderItem.places[0].assessedValue) !== 0) {
            this._orderService.setAssessedValueToOrder(
              orderItem,
              orderItem.places[0].assessedValue
            );
          }
        } else {
          orderItem.places.forEach((place, idx) => {
            this._orderService.setAssessedValueToOrderPlace(
              orderItem,
              idx,
              place.assessedValue
            );
          });
        }

        orderItem.conditions.otherConditions = order.otherConditionsText;

        switch (order.executorRoleType) {
          case "0":
            orderItem.executorRoleType = ExecutorRoleTypeEnum.Any;
            break;
          case "1":
            orderItem.executorRoleType = ExecutorRoleTypeEnum.Forwarding;
            break;
          case "2":
            orderItem.executorRoleType = ExecutorRoleTypeEnum.Delivery;
            break;
          default:
            orderItem.executorRoleType = ExecutorRoleTypeEnum.Any;
        }

        if (order.isForwarded) {
          orderItem.orderType =
            order.forwardToOrganizationIds.length > 0
              ? OrderTypeEnum.ToExecutor
              : OrderTypeEnum.ToHub;
          orderItem.forwardedPrice = order.forwardedPrice
            ? order.forwardedPrice.toString()
            : "";
          orderItem.conditions.documentExchangeDaysAfterFreightUnloading =
            order.documentExchangeDaysAfterFreightUnloading;
          orderItem.conditions.defermentOfPaymentDays =
            order.daysAfterDocumentsReceiveForPayment;
          orderItem.conditions.documentsFormat = order.documentsFormat;

          if (
            order.forwardToOrganizationIds.length > 0 &&
            orderItem.executorOrganization != null
          ) {
            let forwardToOrganizations =
              forwardToOrganizationsByExecutorLineClient[
                orderItem.executorOrganization.id
              ];
            if (forwardToOrganizations == null) {
              const res = await getOrganizationsToForwardOrder(
                orderItem.executorRoleType,
                orderItem.executorOrganization.id,
                "",
                true
              );

              forwardToOrganizations = res.isSuccess ? res.entity : [];
            }

            if (forwardToOrganizations.length > 0) {
              orderItem.forwardToOrganizations = order.forwardToOrganizationIds
                .map(x => {
                  return forwardToOrganizations.find(org => org.id === x);
                })
                .filter(x => x != null) as OrganizationViewInterface[];
            }
          }

          if (
            orderItem.orderType === OrderTypeEnum.ToHub ||
            orderItem.orderType === OrderTypeEnum.ToExecutor
          ) {
            orderItem.isAuction = order.isAuction;
            orderItem.auctionTime = order.auctionTime;
            orderItem.auctionBidStep =
              order.auctionBidStep != null && order.auctionBidStep > 0
                ? order.auctionBidStep.toString()
                : "500";
          }

          if (
            orderItem.orderType === OrderTypeEnum.ToHub ||
            (orderItem.orderType === OrderTypeEnum.ToExecutor &&
              orderItem.forwardToOrganizations != null &&
              orderItem.forwardToOrganizations.length > 1)
          ) {
            orderItem.isAuctionAutoComplete = order.isAuctionAutoComplete;
            orderItem.isPriceOnlyDescend = order.isPriceOnlyDescend;
            orderItem.isAuctionAutoExtend = order.isAuctionAutoExtend;
            orderItem.isAuctionAutoExtend = order.isAuctionAutoExtend;
            orderItem.extendTimeLimit = order.extendTimeLimit ?? "";
            orderItem.auctionExtendActivationTime =
              order.auctionExtendActivationTime ?? "";
            orderItem.auctionExtendTime = order.auctionExtendTime ?? "";
          }
        }

        this._orderService.setPriceToOrder(orderItem, orderItem.price);

        return orderItem;
      })
    );
  }
}
